// React Redux Core
import React from 'react';
import ReactDOM from 'react-dom';
// Material-UI and other Libraries
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
// Adapify
import theme from './theme';
import App from './App';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  rootElement,
);
