// React Redux Core
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// Adapify
import Website from './Website';
import Contact from './Website/Contact';
import Support from './Website/Support';

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/support" component={Support} />
      <Route path="/contact" component={Contact} />
      <Route path="/" component={Website} />
    </Switch>
  </BrowserRouter>
);

export default App;
