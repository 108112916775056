// React Core
import React from 'react';
// Material-UI and other Libraries
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// Adapify
import gtag from '../Utilities/Gtag';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
    [theme.breakpoints.down(899)]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  navLinks: {
    [theme.breakpoints.down(899)]: {
      width: '100%',
      margin: 10,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  link: {
    fontWeight: 600,
    justifySelf: 'center',
    margin: theme.spacing(1, 1.5),
    '&:hover': {
      textDecoration: 'none',
      color: '#777',
    },
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const { current } = props;
  gtag();

  return (
    <>
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
            <a href="/"><img style={{ maxWidth: 200, marginTop: 10 }} src={`${process.env.PUBLIC_URL}/logo-wide.png`} alt="logo" /></a>
          </Typography>
          <nav className={classes.navLinks}>
            <Link
              variant="button"
              color={current === 'home' ? 'primary' : 'textPrimary'}
              href="/"
              className={classes.link}
              onClick={() => {
                gtag('event', 'click', {
                  event_category: 'Header - Home Link Clicked',
                  event_label: 'Header - Home Link Clicked',
                  value: 'Header - Home Link Clicked',
                });
              }}
            >
              Home
            </Link>
            <Link
              variant="button"
              color={current === 'partner' ? 'primary' : 'textPrimary'}
              href="/map"
              className={classes.link}
              onClick={() => {
                gtag('event', 'click', {
                  event_category: 'Header - Map Link Clicked',
                  event_label: 'Header - Map Link Clicked',
                  value: 'Header - Map Link Clicked',
                });
              }}
            >
              Category
            </Link>
            <Link
              variant="button"
              color={current === 'partner' ? 'primary' : 'textPrimary'}
              href="/map"
              className={classes.link}
              onClick={() => {
                gtag('event', 'click', {
                  event_category: 'Header - Map Link Clicked',
                  event_label: 'Header - Map Link Clicked',
                  value: 'Header - Map Link Clicked',
                });
              }}
            >
              Geography
            </Link>
          </nav>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
