import { createMuiTheme } from '@material-ui/core/styles';

const adapifyTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#565de0',
      main: '#0033ad',
      dark: '#000f7c',
      contrastText: '#fff',
      gray: '#777',
    },
    secondary: {
      light: '#d5fa81',
      main: '#a2c751',
      dark: '#71961f',
      contrastText: '#000',
      gray: '#dadada',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },

  // Override MuiTab in theme.js
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '#root': {
          overflow: 'auto',
        },
        body: { margin: 0 },
        '*::-webkit-scrollbar': {
          width: '0.4em',
          height: '0.4em',
          overflow: 'overlay',
        },
        '*::-webkit-scrollbar *': {
          background: 'transparent',
        },
        '*::-webkit-scrollbar-track': {
          display: 'none',
        },
        '*::-webkit-scrollbar-thumb': {
          background: '#a3c755',
          borderRadius: '10px',
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 2,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 4,
      },
    },
    MuiCardHeader: {
      root: {
        borderRadius: '4px 4px 0 0',
      },
    },
    MuiCardActions: {
      root: {
        borderRadius: '0 0 4px 4px',
      },
    },
    MuiTab: {
      labelIcon: {
        minHeight: 60,
        paddingTop: 12,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: '#484848',
        color: '#fff',
      },
    },
    MuiTableBody: {
      root: {
        overflow: 'auto',
      },
    },
    MuiTableCell: {
      root: {
        minWidth: '140px',
      },
      body: {
        border: 'none',
      },
      footer: {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: '#fff',
      },
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },
  },
});

export default adapifyTheme;
