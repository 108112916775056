import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-unresolved
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

const useStyles = makeStyles(() => ({
  map: {
    '& #latlng': {
      display: 'none !important',
    },
    '& .mapboxgl-control-container': {
      display: 'none',
    },
  },
}));

mapboxgl.accessToken = 'pk.eyJ1IjoicGhpbGJlcnQ0NDAiLCJhIjoiY2twdmh3Mnc2MDE0bzJ2bWZyNng1cmVhYyJ9.hJcViV_8eTzMWliULOYD2g';

const OpenMap = () => {
  const classes = useStyles();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(5.2239);
  const [lat, setLat] = useState(26.4373);
  const [zoom, setZoom] = useState(1.55);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/philbert440/ckpvtt0x412kg17rw18nj3hac',
      center: [lng, lat],
      zoom,
    });
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
    map.current.scrollZoom.disable();
  });

  return (
    <div className={classes.map}>
      <div
        id="latlng"
        style={{
          display: 'inline-block',
          position: 'absolute',
          top: 0,
          left: 0,
          margin: 12,
          backgroundColor: '#404040',
          color: '#ffffff',
          zIndex: '1 !important',
          padding: 6,
          fontWeight: 'bold',
        }}
        hidden
      >
        Longitude:
        {lng}
        | Latitude:
        {lat}
        | Zoom:
        {zoom}
      </div>
      <div
        ref={mapContainer}
        style={{
          height: 800,
        }}
      />
    </div>
  );
};

export default OpenMap;
