// React Core
import React from 'react';
// Material-UI and other Libraries
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
// MUI Icons and Font Awesome
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';
import OpenMap from './OpenMap';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    color: theme.palette.primary.main,
  },
}));

const Website = () => {
  const classes = useStyles();
  gtag();
  gtag('event', 'page_view', {
    page_title: 'Home',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/`,
    page_path: '/',
  });

  return (

    <>
      <CssBaseline />
      <Header current="home" />
      {/* Hero unit */}
      <div className={classes.heroContent}>
        <OpenMap />
      </div>
      {/* End hero unit */}

      <Footer />
    </>
  );
};

export default Website;
