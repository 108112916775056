// React Core
import React, { useState } from 'react';
// Material-UI and other Libraries
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
// Icons
import EmailIcon from '@material-ui/icons/Email';
import Alert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  contactWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  contact: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '90%',
  },
  contactContent: {
    padding: 2,
    margin: '40px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(900)]: {
      flexDirection: 'column',
    },
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mssgContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    alignItems: 'center',
    [theme.breakpoints.down(900)]: {
      width: '100%',
    },
  },
  divider: {
    height: 340,
    marginTop: '40px',
    marginBottom: '20px',
    [theme.breakpoints.down(900)]: {
      display: 'none',
    },
  },
  otherHalf: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    textAlign: 'center',
    justifyContent: 'center',
    height: 400,
    [theme.breakpoints.down(900)]: {
      width: '100%',
      height: 'auto',
      marginTop: 10,
    },
  },
  contactCont: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(900)]: {
      flexDirection: 'row',
    },
  },
  messageField: {
    fill: '#fff',
    width: '92%',
    height: 50,
    [theme.breakpoints.down(900)]: {
      width: '87%',
    },
  },
  buttonWrapper: {
    marginTop: 30,
  },
  topFields: {
    marginTop: 20,
    marginBottom: 0,
    width: '95%',
    padding: '0 2.5%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  topField: {
    width: '49%',
  },
  botField: {
    width: '90%',
  },
  checkWrapper: {
    zIndex: 100,
    position: 'absolute',
    right: '10000px',
    width: '0px',
    height: '0px',
  },
}));

const Contact = () => {
  const classes = useStyles();
  gtag();
  gtag('event', 'page_view', {
    page_title: 'Contact',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/contact`,
    page_path: '/contact',
  });
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [custMssg, setCustMssg] = useState('');
  const [message, setMessage] = useState('');
  const [messageOn, setMessageOn] = useState(false);
  const [severity, setSeverity] = useState('error');
  const [poohCheck, setPoohCheck] = useState(false);
  const changePooh = () => {
    setPoohCheck(true);
  };
  const submitForm = async (data) => {
    gtag('event', 'click', {
      event_category: data.origin,
      event_label: 'Contact - Form Submit',
      value: 'Contact - Form Submit',
    });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    const body = JSON.stringify(data);
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body,
    };
    const result = await fetch(`${process.env.REACT_APP_ADAPIFY_BACKSITE_URL}/lead_resource`, requestOptions);

    if (result.status === 200) {
      setMessage('Your form has been submitted. We\'ll be in touch within 24 hours');
      setMessageOn(true);
    }
    if (result.ok === false) {
      setMessage(result.message);
      setMessageOn(true);
    }
  };

  const handleSubmit = () => {
    if ((email.indexOf('@') !== -1) && (email.indexOf('.') !== -1) && (email.length >= 5) && (phone.length === 10)) {
      submitForm({
        origin: 'Support_Form',
        name,
        phone,
        email,
        body: custMssg,
        setMessage,
        setMessageOn,
        data: '{}',
      });
      setSeverity('success');
      setMessageOn(true);
    } else {
      setSeverity('error');
      setMessage('Please enter a valid email address and a valid ten digit phone number');
      setMessageOn(true);
    }
  };

  return (
    <>
      <Header />
      <Container className={classes.contactWrapper}>
        <div className={classes.contact}>
          <div className={classes.contactContent}>
            <Container className={classes.mssgContainer}>
              <Typography variant="h5">CONTACT US</Typography>
              <Container className={classes.topFields}>
                <TextField
                  className={classes.topField}
                  variant="outlined"
                  margin="normal"
                  name="name"
                  label="Name"
                  type="name"
                  id="name"
                  autoComplete="name"
                  color="primary"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  className={classes.topField}
                  variant="outlined"
                  margin="normal"
                  name="phone"
                  label="Phone"
                  type="phone"
                  id="phone"
                  autoComplete="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Container>
              <TextField
                className={classes.botField}
                variant="outlined"
                margin="normal"
                name="email"
                label="Email"
                type="email"
                id="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                className={(classes.messageField, classes.botField)}
                variant="outlined"
                margin="normal"
                name="message"
                label="Message"
                id="mesage"
                autoComplete="message"
                multiline
                rows={3}
                rowsMax={4}
                value={custMssg}
                onChange={(e) => setCustMssg(e.target.value)}
              />
              <div className={classes.checkWrapper}>
                Do you agree to our terms and conditions?
                <Checkbox checked={poohCheck} onChange={changePooh} />
              </div>
              <Fade
                in={messageOn}
                timeout={500}
                unmountOnExit
              >
                <Alert severity={severity}>
                  {message}
                </Alert>
              </Fade>
              <div className={classes.buttonWrapper}>
                <Button
                  className={classes.emailButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => handleSubmit()}
                >
                  Send Message
                </Button>
              </div>
            </Container>
            <Divider className={classes.divider} orientation="vertical" variant="middle" flexItem />
            <Container className={classes.otherHalf}>
              <Container
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: 35,
                }}
              >
                <Container className={classes.contactCont} style={{ marginBottom: 20 }}>
                  <Typography variant="subtitle2">
                    Fill out this form and let us know how we can help. Someone from our team will
                    get back to you within 24hrs.
                  </Typography>
                </Container>
                <Container className={classes.contactCont}>
                  <EmailIcon fontSize="medium" style={{ marginRight: 8 }} color="secondary" />
                  <h4> support@adapify.com </h4>
                </Container>
              </Container>
            </Container>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Contact;
