/*
 * Use this code for initialization / pageview
  gtag();
 * Use this code for event triggers
  gtag('event', 'click', {
    'event_category': 'homepage',
    'event_label': 'CTA Banner',
    'value': 'Learn more button clicked'
  });
 *
 */
const gtag = (item, action, data) => {
  if (item) {
    window.gtag(item, action, data);
  } else {
    window.gtag('config', `${process.env.REACT_APP_GOOGLE_GTAG_ID}`, { send_page_view: false });
    window.gtag('config', `${process.env.REACT_APP_GOOGLE_GTAG_ID2}`, { send_page_view: false });
  }
};

export default gtag;
