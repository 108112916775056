// React Core
import React from 'react';
// Material-UI and other Libraries
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
// MUI Icons and Font Awesome
// Adapify
import gtag from '../Utilities/Gtag';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  phoneIcon: {
    color: theme.palette.primary.main,
    fontSize: 14,
    marginTop: 4,
    marginRight: 4,
  },
}));

const Footer = () => {
  const classes = useStyles();
  gtag();

  return (
    <>
      {/* Footer */}
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Grid container spacing={4} justify="space-evenly">
          <Grid item xs={6} sm={3} key="Logo">
            <Typography variant="h6" color="textPrimary" gutterBottom>
              <a href="/"><img xs={6} sm={3} style={{ maxWidth: 80, height: 'auto', marginTop: 10 }} src={`${process.env.PUBLIC_URL}/logo.png`} alt="logo" /></a>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3} key="company">
            <Typography variant="h6" color="textPrimary" gutterBottom>
              Company
            </Typography>
            <ul>
              <li key="contact">
                <Link
                  href="/contact"
                  variant="subtitle1"
                  color="textSecondary"
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Footer - Contact Link Clicked',
                      event_label: 'Footer - Contact Link Clicked',
                      value: 'Footer - Contact Link Clicked',
                    });
                  }}
                >
                  Contact
                </Link>
              </li>
              <li key="about">
                <Link
                  href="https://adapify.com/team"
                  variant="subtitle1"
                  color="textSecondary"
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Footer - Contact Link Clicked',
                      event_label: 'Footer - Contact Link Clicked',
                      value: 'Footer - Contact Link Clicked',
                    });
                  }}
                >
                  Our Team
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={6} sm={3} key="services">
            <Typography variant="h6" color="textPrimary" gutterBottom>
              Services
            </Typography>
            <ul>
              <li key="ourPool">
                <Link
                  href="https://adapools.org/pool/884e3b60b361f9b714e06b24063d7b7225b17f64cb46d8ee8f0c821a"
                  variant="subtitle1"
                  color="textSecondary"
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Our Pool - Partner Link Clicked',
                      event_label: 'Our Pool - Partner Link Clicked',
                      value: 'Our Pool - Partner Link Clicked',
                    });
                  }}
                >
                  Our Pool
                </Link>
              </li>
              <li key="support">
                <Link
                  href="/support"
                  variant="subtitle1"
                  color="textSecondary"
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Footer - Support Link Clicked',
                      event_label: 'Footer - Support Link Clicked',
                      value: 'Footer - Support Link Clicked',
                    });
                  }}
                >
                  Support
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={6} sm={3} key="Resources">
            <Typography variant="h6" color="textPrimary" gutterBottom>
              Resources
            </Typography>
            <ul>
              <li key="guide-delegate">
                <Link
                  href="https://cardano.org/stake-pool-delegation/"
                  variant="subtitle1"
                  color="textSecondary"
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Footer - Stake Pool Delegation Link Clicked',
                      event_label: 'Footer - Stake Pool Delegation Link Clicked',
                      value: 'Footer - Stake Pool Delegation Link Clicked',
                    });
                  }}
                >
                  Pool Delegation
                </Link>
              </li>
              <li key="guide-spo">
                <Link
                  href="https://cardano.org/stake-pool-operation/"
                  variant="subtitle1"
                  color="textSecondary"
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Footer - Stake Pool Operation Link Clicked',
                      event_label: 'Footer - Stake Pool Operation Link Clicked',
                      value: 'Footer - Stake Pool Operation Link Clicked',
                    });
                  }}
                >
                  Pool Operation
                </Link>
              </li>
              <li key="sqft">
                <Link
                  href="https://f2lb.org"
                  variant="subtitle1"
                  color="textSecondary"
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Footer - Square Foot Link Clicked',
                      event_label: 'Footer - Square Foot Link Clicked',
                      value: 'Footer - Square Foot Link Clicked',
                    });
                  }}
                >
                  F2LB Community
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
        <br />
        <Box mt={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            {'© 2014-'}
            {`${new Date().getFullYear()} `}
            <Link
              color="primary"
              href="https://adapify.com/"
              onClick={() => {
                gtag('event', 'click', {
                  event_category: 'Footer - Precription Soil Analysis Link Clicked',
                  event_label: 'Footer - Precription Soil Analysis Link Clicked',
                  value: 'Footer - Precription Soil Analysis Link Clicked',
                });
              }}
            >
              Adapify Inc.
            </Link>
            {' All Rights Reserved.'}
          </Typography>
          <br />
        </Box>
      </Container>
      {/* End footer */}
    </>
  );
};

export default Footer;
